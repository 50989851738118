<template>
  <div class="b-page">
    <div class="b-page__content">
      <h1 class="main-page">Общество с ограниченной ответственностью
        «ЮКОН»</h1>
      <div class="container block-margin-bottom">
        <div class="mini_banners">
          <div class="container block-margin">

            <table class="info-page">
              <tr>
                <td>Полное наименование организации</td>
                <td>Общество с ограниченной ответственностью
                  «ЮКОН»
                </td>
              </tr>
              <tr>
                <td>Краткое наименование организации</td>
                <td>ООО «ЮКОН»</td>
              </tr>
              <tr>
                <td>ИНН</td>
                <td>7728384838</td>
              </tr>
              <tr>
                <td>КПП</td>
                <td>772801001</td>
              </tr>
              <tr>
                <td>ОГРН</td>
                <td>5177746150852</td>
              </tr>
              <tr>
                <td>Код ИФНС</td>
                <td>7728</td>
              </tr>
              <tr>
                <td>Юридический адрес</td>
                <td>117465, г.Москва, РФ, г. Москва,
                  Генерала Тюленева ул., дом3, кв164
                </td>
              </tr>
              <tr>
                <td>Фактический адрес</td>
                <td>117546, г.Москва, Ступинский проезд д.7 стр.2</td>
              </tr>
              <tr>
                <td>Телефон</td>
                <td> +7 (977) 929 64 06
                </td>
              </tr>
              <tr>
                <td>Электронная почта</td>
                <td>ykon2017@mail.ru</td>
              </tr>
              <tr>
                <td>Сайт</td>
                <td>www.pto-ykon.ru</td>
              </tr>
              <tr>
                <td>ФИО руководителя</td>
                <td>Баденский Василий Александрович</td>
              </tr>
              <tr>
                <td>р\сч</td>
                <td>40702810801820000112</td>
              </tr>
              <tr>
                <td>Банк</td>
                <td>в АО "АЛЬФА-БАНК</td>
              </tr>
              <tr>
                <td>БИК</td>
                <td>044525593</td>
              </tr>
              <tr>
                <td>к\сч</td>
                <td>30101810200000000593</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Info"
}
</script>

<style scoped>
.info-page{
  font-size: 120%;
  width: 100%;
}
.info-page tr{
  border-bottom: #5e5e5e 1px solid;
  height: 40px;
}
</style>